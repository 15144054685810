const {
  NODE_ENV: nodeEnv,
  DEBUG: deb,
  PORT: port,
  APP_URL: appUrl,
  API_URL: apiUrl,
  PORTAL_URL: portalUrl,
  SENTRY_DSN: sentryDsn,
  SENTRY_RELEASE: sentryRelease
} = process.env

const debug = deb === 'true'

module.exports = {
  nodeEnv,
  debug,
  port,
  appUrl,
  apiUrl,
  portalUrl,
  sentryDsn,
  sentryRelease
}
